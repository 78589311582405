<script>
import MainSelect from "@/components/helpers/MainSelect";
export default {
  components: { MainSelect },

  props: {
    partner: {
      type: Object,
      required: true,
    },

    partnerIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      selectedTariff: null,
      selectedSide: null,

      tariffs: [
        {
          id: 1,
          code: "smart_c",
          name: "Клиент Смарт",
        },
        {
          id: 1,
          code: "standart_c",
          name: "Клиент Стандарт",
        },
        {
          id: 2,
          code: "business_d",
          name: "Бизнес",
        },
        {
          id: 3,
          code: "premium_d",
          name: "Премиум",
        },
        {
          id: 4,
          code: "vip_d",
          name: "Франшиза",
        },
      ],
      sides: [
        {
          id: "left",
          name: "L",
        },
        {
          id: "right",
          name: "R",
        },
      ],
    };
  },

  watch: {
    computedPartner: {
      deep: true,
      handler() {
        this.$emit("updatePartner", this.computedPartner);
      },
    },

    partner: {
      deep: true,
      handler() {
        this.selectedSide = this.partner.side;
        this.selectedTariff = this.partner.tariff;
      },
    },
  },

  computed: {
    formattedCVLeft() {
      return this.selectedSide.id === "left" ? this.partner.rewards.CV : 0;
    },

    formattedCVRight() {
      return this.selectedSide.id === "right" ? this.partner.rewards.CV : 0;
    },

    computedPartner() {
      return {
        index: this.partnerIndex,
        side: this.selectedSide,
        tariff: this.selectedTariff,
      };
    },

    showTeamBonus() {
      return (
        this.partner.teamBonus.CVLeftBefore &&
        this.partner.teamBonus.CVRightBefore
      );
    },
  },

  beforeMount() {
    this.selectedSide = this.partner.side;
    this.selectedTariff = this.partner.tariff;
  },
};
</script>

<template>
  <div class="calculator-personal-gain-partner">
    <div class="calculator-personal-gain-partner__wrapper">
      <button
        class="calculator-personal-gain-partner__close"
        @click="$emit('deletePartner')"
      >
        <span></span>
      </button>

      <main-select
        class="calculator-personal-gain-partner__tariff"
        :selected-option.sync="selectedTariff"
        :options="tariffs"
        style-type="background-gray"
        title="Тариф"
      />

      <main-select
        class="calculator-personal-gain-partner__side"
        :selected-option.sync="selectedSide"
        :options="sides"
        style-type="background-gray"
        title="Сторона"
      />

      <div class="calculator-personal-gain-partner__info info">
        <h5 class="info__title">
          За приглашение {{ partnerIndex + 1 }} партнера
        </h5>

        <div class="info__values">
          <p class="info__value info__value_rc">
            {{ partner.rewards.RC }} <span>RC</span>
          </p>

          <p class="info__value info__value_pv">
            {{ partner.rewards.PV }} <span>PV</span>
          </p>

          <p
            class="info__value info__value_cv info__value_cv-left"
            :class="{ info__value_dark: formattedCVLeft === 0 }"
          >
            <span class="info__icon"></span>{{ formattedCVLeft }}
            <span>CV</span>
          </p>

          <p
            class="info__value info__value_cv info__value_cv-right"
            :class="{ info__value_dark: formattedCVRight === 0 }"
          >
            <span class="info__icon"></span>{{ formattedCVRight }}
            <span>CV</span>
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="showTeamBonus"
      class="calculator-personal-gain-partner__team-bonus team-bonus"
    >
      <h5 class="team-bonus__title">Командный бонус</h5>

      <div class="team-bonus__info info">
        <div class="info__values">
          <p class="info__value info__value_rc">
            {{ partner.teamBonus.reward }} <span>RC</span>
          </p>

          <p></p>

          <div class="info__value-wrapper">
            <div class="info__line"></div>

            <p
              class="info__value info__value_cv info__value_cv-left info__value_dark"
            >
              <span class="info__icon"></span>
              {{ partner.teamBonus.CVLeftBefore }}
              <span>CV</span>
            </p>
            <p
              class="info__value info__value_cv info__value_cv-left"
              :class="{
                info__value_opacity: partner.teamBonus.CVLeftAfter === 0,
              }"
            >
              <span class="info__icon"></span>
              {{ partner.teamBonus.CVLeftAfter }}
              <span>CV</span>
            </p>
          </div>

          <div class="info__value-wrapper">
            <p
              class="info__value info__value_cv info__value_cv-right info__value_dark"
            >
              <span class="info__icon"></span>
              {{ partner.teamBonus.CVRightBefore }}
              <span>CV</span>
            </p>
            <p
              class="info__value info__value_cv info__value_cv-right"
              :class="{
                info__value_opacity: partner.teamBonus.CVRightAfter === 0,
              }"
            >
              <span class="info__icon"></span>
              {{ partner.teamBonus.CVRightAfter }}
              <span>CV</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.calculator-personal-gain-partner {
  &:not(:last-child) {
    margin-bottom: $space-m;
  }

  &__wrapper {
    background-color: $light-primary;
    border-radius: $space-s;
    padding: $space-l;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  &__close {
    position: absolute;
    right: $space-m;
    top: $space-m;
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      mask: url("../../assets/icons/close_bold.svg") 0 0 / cover no-repeat;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: $dark-fifth;
    }

    &:hover {
      span {
        background: $dark-primary;
      }
    }

    &:active {
      span {
        background: $dark-third;
      }
    }
  }

  &__tariff {
    margin-right: $space-m;
    width: 100%;
    max-width: 190px;

    .main-select {
      max-width: 190px;
    }
  }

  &__side {
    margin-right: $space-m;
    width: 100%;
    max-width: 140px;

    .main-select {
      max-width: 140px;
    }
  }

  .info {
    margin-left: auto;

    &__title {
      margin-bottom: $space-s;
      @include text-2;
      color: $dark-sixth;
    }

    &__values {
      display: grid;
      align-items: center;
      grid-template-columns: 110px 75px 77px 77px;
      column-gap: $space-s;
    }

    &__value {
      @include body-1-bold;
      color: $dark-primary;
      display: flex;
      align-items: center;

      span {
        &:last-child {
          margin-left: $space-xs;
        }
      }

      &_rc {
        span {
          color: $gold-main;
        }
      }

      &_pv {
        span {
          color: $money-pv;
        }
      }

      &_cv {
        span {
          color: $money-cv;
        }
      }

      &_cv-left {
        .info__icon {
          mask: url("../../assets/icons/binary_account_position_left.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_cv-right {
        .info__icon {
          mask: url("../../assets/icons/binary_account_position_right.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_opacity {
        color: $dark-primary !important;
        opacity: 0.3;

        span {
          color: $dark-primary !important;
        }

        .info__icon {
          background: $dark-primary !important;
        }
      }
    }

    &__icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: $dark-fifth;
      margin-right: $space-xs;
    }
  }

  .team-bonus {
    background-color: $light-third;
    border-radius: $space-s;
    padding: $space-m $space-l;
    margin-top: $space-xs;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__title {
      @include text-2;
      color: $dark-primary;
    }
  }

  .info {
    &__value-wrapper {
      position: relative;

      p {
        &:first-of-type {
          margin-bottom: $space-xs;
        }
      }
    }

    &__line {
      position: absolute;
      top: 11px;
      left: -1px;
      height: 1px;
      width: 158px;
      background-color: $light-sixth;
    }

    &__value {
      &_dark {
        color: $dark-sixth !important;

        span {
          color: $dark-sixth !important;
        }

        .info__icon {
          background: $dark-sixth !important;
        }
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1220px) {
    .info {
      min-width: 100%;
      margin-top: $space-xl;
    }
  }

  @media (max-width: 900px) {
    .info {
      min-width: 100%;
      margin-top: $space-xl;
    }
  }

  @media (max-width: 576px) {
    &__tariff {
      max-width: 150px;

      .main-select {
        max-width: 150px;
      }
    }

    &__side {
      max-width: 104px;

      .main-select {
        max-width: 104px;
        min-width: auto;
      }
    }

    .info {
      &__values {
        min-width: auto;
        max-width: 286px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 375px) {
    &__wrapper {
      padding: $space-m;
    }

    &__tariff {
      max-width: 120px;

      .main-select {
        max-width: 120px;
      }
    }

    &__side {
      max-width: 96px;

      .main-select {
        max-width: 96px;
      }
    }

    .info {
      &__values {
        display: grid;
        grid-template-columns: repeat(2, minmax(70px, 100px));
        grid-template-rows: repeat(2, auto);
        justify-content: flex-start;
        grid-column-gap: $space-s;
        grid-row-gap: $space-s;
      }
    }

    .team-bonus {
      padding: $space-m;

      .info {
        &__line {
          width: calc(100% + 72px);
        }
      }
    }
  }
}
</style>
