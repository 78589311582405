export const CALCULATOR_PARTNER_REWARDS = {
  business_d: {
    teamBonus: 100,

    smart_c: {
      RC: 800,
      PV: 1,
      CV: 0,
    },

    standart_c: {
      RC: 1000,
      PV: 1,
      CV: 1,
    },

    business_d: {
      RC: 1200,
      PV: 12,
      CV: 12,
    },

    premium_d: {
      RC: 6400,
      PV: 20,
      CV: 20,
    },

    vip_d: {
      RC: 16000,
      PV: 20,
      CV: 20,
    },
  },

  premium_d: {
    teamBonus: 125,

    smart_c: {
      RC: 800,
      PV: 1,
      CV: 0,
    },

    standart_c: {
      RC: 1000,
      PV: 1,
      CV: 1,
    },

    business_d: {
      RC: 1200,
      PV: 12,
      CV: 12,
    },

    premium_d: {
      RC: 6400,
      PV: 64,
      CV: 64,
    },

    vip_d: {
      RC: 16000,
      PV: 64,
      CV: 64,
    },
  },

  vip_d: {
    teamBonus: 150,

    smart_c: {
      RC: 800,
      PV: 1,
      CV: 0,
    },

    standart_c: {
      RC: 1000,
      PV: 1,
      CV: 1,
    },

    business_d: {
      RC: 1200,
      PV: 12,
      CV: 12,
    },

    premium_d: {
      RC: 6400,
      PV: 64,
      CV: 64,
    },

    vip_d: {
      RC: 16000,
      PV: 160,
      CV: 160,
    },
  },
};
