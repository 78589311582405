<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    totalBonusQuickstart: {
      type: Number,
      required: true,
    },

    totalBenefit: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentBonusQuickstartReward: 0,
      loading: false,
    };
  },

  watch: {
    item: {
      deep: true,
      handler() {
        this.currentBonusQuickstartReward = this.defineBonusQuickstartReward();
      },
    },
  },

  computed: {
    formattedRewardTitle() {
      return this.currentBonusQuickstartReward !== 0
        ? "Вознаграждение при выполнении бонуса"
        : "Бонус не начисляется";
    },

    currentReward0() {
      return this.currentBonusQuickstartReward === 0;
    },

    tooltip150000() {
      return this.currentBonusQuickstartReward === 0
        ? {
            content:
              "Вы получите 150 000 RC вознаграждения, если минимальный подключенный тариф будет Франшиза, и пустые места заполнятся",
          }
        : this.currentBonusQuickstartReward !== 150000
        ? "Вы получите 150 000 RC вознаграждения, если минимальный подключенный тариф будет Франшиза"
        : {
            content: "",
            trigger: "manual",
          };
    },

    currentReward150000() {
      return this.currentBonusQuickstartReward === 150000;
    },

    tooltip70000() {
      return this.currentBonusQuickstartReward === 0
        ? {
            content:
              "Вы получите 70 000 RC вознаграждения, если минимальный подключенный тариф будет Премиум, и пустые места заполнятся",
          }
        : this.currentBonusQuickstartReward !== 70000
        ? "Вы получите 70 000 RC вознаграждения, если минимальный подключенный тариф будет Премиум"
        : {
            content: "",
            trigger: "manual",
          };
    },

    currentReward70000() {
      return this.currentBonusQuickstartReward === 70000;
    },

    tooltip15000() {
      return this.currentBonusQuickstartReward === 0
        ? {
            content:
              "Вы получите 15 000 RC вознаграждения, если минимальный подключенный тариф будет Бизнес, и пустые места заполнятся",
          }
        : this.currentBonusQuickstartReward !== 15000
        ? "Вы получите 15 000 RC вознаграждения, если минимальный подключенный тариф будет Бизнес"
        : {
            content: "",
            trigger: "manual",
          };
    },

    currentReward15000() {
      return this.currentBonusQuickstartReward === 15000;
    },
  },

  methods: {
    formatLegTariffClass(tariff) {
      return `leg__tariff_${tariff}`;
    },

    defineBonusQuickstartReward() {
      this.loading = true;

      let allTariffs = [];

      this.item.left.forEach((tariff) => {
        allTariffs.push(tariff.code);
      });

      this.item.right.forEach((tariff) => {
        allTariffs.push(tariff.code);
      });

      if (
        !allTariffs.includes("empty") &&
        !allTariffs.includes("smart_c") &&
        !allTariffs.includes("standart_c") &&
        allTariffs.includes("business_d")
      ) {
        this.loading = false;
        return 15000;
      } else if (
        !allTariffs.includes("empty") &&
        !allTariffs.includes("smart_c") &&
        !allTariffs.includes("standart_c") &&
        !allTariffs.includes("business_d") &&
        allTariffs.includes("premium_d")
      ) {
        this.loading = false;
        return 70000;
      } else if (
        !allTariffs.includes("empty") &&
        !allTariffs.includes("smart_c") &&
        !allTariffs.includes("standart_c") &&
        !allTariffs.includes("business_d") &&
        !allTariffs.includes("premium_d") &&
        allTariffs.includes("vip_d")
      ) {
        this.loading = false;
        return 150000;
      } else {
        this.loading = false;
        return 0;
      }
    },
  },

  beforeMount() {
    this.currentBonusQuickstartReward = this.defineBonusQuickstartReward();
  },
};
</script>

<template>
  <div v-if="!loading" class="quickstart-bonus-item">
    <div class="quickstart-bonus-item__wrapper">
      <div class="quickstart-bonus-item__legs">
        <div class="quickstart-bonus-item__leg leg">
          <h5 class="leg__title">Команда L</h5>

          <div
            v-for="(tariff, index) in item.left"
            :key="index"
            class="leg__tariff-wrapper"
          >
            <p
              v-if="tariff.code !== 'empty'"
              class="leg__tariff"
              :class="formatLegTariffClass(tariff.code)"
            >
              <span></span> {{ tariff.name }}
            </p>
            <p v-else class="leg__tariff leg__tariff_empty">Пусто</p>
          </div>
        </div>

        <div class="quickstart-bonus-item__leg leg">
          <h5 class="leg__title">Команда R</h5>

          <div
            v-for="(tariff, index) in item.right"
            :key="index"
            class="leg__tariff-wrapper"
          >
            <p
              v-if="tariff.code !== 'empty'"
              class="leg__tariff"
              :class="formatLegTariffClass(tariff.code)"
            >
              <span></span> {{ tariff.name }}
            </p>
            <p v-else class="leg__tariff leg__tariff_empty">Пусто</p>
          </div>
        </div>

        <div class="legs__border-bottom"></div>
      </div>

      <div class="quickstart-bonus-item__reward reward">
        <div class="reward__wrapper">
          <p class="reward__title">{{ formattedRewardTitle }}</p>
          <div class="reward__amounts">
            <p
              class="reward__amount"
              :class="{
                reward__amount_selected: currentReward0,
              }"
            >
              0 RC
            </p>
            <p
              v-tooltip="tooltip15000"
              class="reward__amount"
              :class="{
                reward__amount_selected: currentReward15000,
              }"
            >
              15 000 RC
            </p>
            <p
              v-tooltip="tooltip70000"
              class="reward__amount"
              :class="{
                reward__amount_selected: currentReward70000,
              }"
            >
              70 000 RC
            </p>
            <p
              v-tooltip="tooltip150000"
              class="reward__amount"
              :class="{
                reward__amount_selected: currentReward150000,
              }"
            >
              150 000 RC
            </p>
          </div>
        </div>

        <div
          v-if="totalBonusQuickstart > 0"
          class="reward__benefit benefit benefit_inside"
        >
          <h5 class="benefit__title">Выгода с ББС</h5>
          <p class="benefit__amount">
            {{ totalBenefit.total + totalBonusQuickstart }} RC
          </p>
        </div>

        <div class="reward__border-bottom"></div>
      </div>
    </div>

    <div v-if="totalBonusQuickstart > 0" class="benefit benefit_outside">
      <h5 class="benefit__title">Выгода с ББС</h5>
      <p class="benefit__amount">
        {{ totalBenefit.total + totalBonusQuickstart }} RC
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quickstart-bonus-item {
  &__wrapper {
    display: flex;
  }

  &:not(:only-of-type) {
    .quickstart-bonus-item {
      &__legs,
      &__reward {
        border-radius: 0;
      }
    }

    &:first-of-type {
      .quickstart-bonus-item {
        &__legs,
        &__reward {
          border-radius: $space-s $space-s 0 0 !important;
        }
      }
    }

    &:last-of-type {
      .quickstart-bonus-item {
        &__legs,
        &__reward {
          border-radius: 0 0 $space-s $space-s !important;
        }
      }
    }

    &:not(:first-of-type) {
      .quickstart-bonus-item {
        &__legs,
        &__reward {
          height: 108px;
        }
      }

      .leg {
        &__title {
          display: none;
        }
      }

      .benefit {
        display: none;
      }
    }

    &:not(:last-of-type) {
      .legs,
      .reward {
        &__border-bottom {
          height: 1px;
          width: calc(100% - 48px); // -48px - padding
          background-color: $light-fifth;
          position: absolute;
          bottom: 0;
          left: 24px;
        }
      }
    }
  }

  &__legs {
    display: grid;
    grid-template-columns: 100px 100px;
    column-gap: 20px;
    background-color: $light-third;
    padding: $space-l;
    height: 146px;
    border-radius: $space-s;
    position: relative;
    margin-right: $space-xs;
  }

  .leg {
    &__title {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: $space-m;
    }

    &__tariff-wrapper {
      &:first-of-type {
        margin-bottom: $space-m;
      }
    }

    &__tariff {
      @include body-1;
      display: flex;
      align-items: center;
      color: $dark-primary;

      span {
        display: none;
        min-width: 16px;
        width: 16px;
        height: 16px;
        background: $dark-primary;
        margin-right: $space-s;
      }

      &_empty,
      &_smart_c,
      &_standart_c {
        color: $light-sixth;
      }

      &_business_d {
        span {
          mask: url("../../assets/icons/tariffs/business_d_dark.svg") 0 0 /
            cover no-repeat;
          display: inline-block;
        }
      }

      &_premium_d {
        span {
          mask: url("../../assets/icons/tariffs/premium_d_dark.svg") 0 0 / cover
            no-repeat;
          display: inline-block;
        }
      }

      &_vip_d {
        span {
          mask: url("../../assets/icons/tariffs/vip_d_dark.svg") 0 0 / cover
            no-repeat;
          display: inline-block;
        }
      }

      &_vip-gold_d {
        span {
          mask: url("../../assets/icons/tariffs/vip_gold_d_dark.svg") 0 0 /
            cover no-repeat;
          display: inline-block;
        }
      }
    }
  }

  .reward {
    display: flex;
    align-items: center;
    flex: auto;
    height: 146px;
    padding: 0 $space-l 0 $space-xl;
    background-color: $light-third;
    border-radius: $space-s;
    position: relative;

    &__title {
      @include text-2;
      color: $dark-primary;
      margin-bottom: $space-xs;
      max-width: 248px;
    }

    &__amounts {
      display: flex;
      margin-right: $space-m;
    }

    &__amount {
      @include text-2-bold;
      color: $light-sixth;
      white-space: nowrap;

      &_selected {
        color: $dark-primary;
      }

      &:not(:last-child) {
        margin-right: $space-s;
      }
    }
  }

  .benefit {
    background-color: $dark-third;
    border-radius: $space-s;
    padding: $space-l;
    margin-left: auto;
    width: 100%;
    max-width: 216px;

    &__title {
      @include text-2-bold;
      color: $light-primary;
      margin-bottom: $space-xs;
    }

    &__amount {
      @include body-1-bold;
      color: $gold-main;
    }

    &_outside {
      display: none;
      margin-top: $space-s;
      max-width: 100%;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    &:first-of-type {
      .benefit_inside {
        display: none !important;
      }
    }

    &:last-of-type {
      .benefit_outside {
        display: block !important;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1054px) {
    .reward {
      padding: 0 20px 0 24px;

      &__amounts {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 900px) {
    margin-bottom: $space-xs;
    border-radius: $space-s;
    overflow: hidden;

    &__wrapper {
      flex-direction: column;

      .legs,
      .reward {
        &__border-bottom {
          display: none;
        }
      }
    }

    &__legs {
      margin: 0;
      height: 146px !important;
    }

    &__legs,
    &__reward {
      border-radius: 0 !important;
    }

    &__reward {
      height: auto !important;
    }

    .leg {
      &__title {
        display: block !important;
      }
    }

    .reward {
      padding: $space-xl $space-l;
    }

    &:first-of-type {
      .benefit_inside {
        display: none !important;
      }
    }

    &:last-of-type {
      .benefit_outside {
        display: block !important;
      }
    }
  }

  @media (max-width: 375px) {
    &__legs {
      height: auto !important;
      padding: $space-m;
    }

    .reward {
      padding: $space-m;
    }

    .benefit {
      padding: $space-m;
    }
  }
}
</style>
