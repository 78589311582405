<script>
import { mapActions } from "vuex";
import MainDocument from "@/components/helpers/MainDocument.vue";

export default {
  components: { MainDocument },

  data() {
    return {
      documents: [],
    };
  },

  methods: {
    ...mapActions({
      loadDocuments: "loadDocuments",
    }),
  },

  mounted() {
    this.loadDocuments({ page: "calculator" }).then((response) => {
      this.documents = response.data.marketing;
    });
  },
};
</script>

<template>
  <div class="calculator-personal-gain-notice">
    <h5 class="calculator-personal-gain-notice__title">
      Рассчитайте свой заработок наперед
    </h5>

    <p class="calculator-personal-gain-notice__text">
      В этом разделе вы сможете строить оптимальные стратегии приглашений и
      оценивать влияние своих действий на прибыль. Вычисления основаны на плане
      агентских вознаграждений:
    </p>

    <template v-if="documents.length > 0">
      <main-document
        v-for="document in documents"
        :key="document.id"
        :title="document.original_name"
        :description="document.description"
        :url="document.url"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.calculator-personal-gain-notice {
  background-color: $light-primary;
  border-radius: $space-m;
  padding: 40px;
  max-width: 692px;

  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__text {
    @include text-2;
    color: $dark-primary;
    margin-bottom: $space-xl;
  }

  @media (max-width: 425px) {
    padding: $space-l;
  }
}
</style>
