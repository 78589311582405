<script>
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="calculator-personal-gain-result">
    <div class="calculator-personal-gain-result__total total">
      <h5 class="total__title">Итоговая выгода</h5>

      <p class="total__value">{{ result.total }} <span>RC</span></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calculator-personal-gain-result {
  background-color: $dark-third;
  border-radius: $space-s;
  padding: $space-l;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  bottom: 32px;

  .total {
    &__title {
      margin-bottom: $space-xs;
      @include text-2-bold;
      color: $light-primary;
    }

    &__value {
      @include body-1-bold;
      color: $gold-main;
    }
  }

  @media (max-width: 425px) {
    bottom: $space-l;
  }

  @media (max-width: 375px) {
    padding: $space-m;
    bottom: $space-m;
  }
}
</style>
