<script>
export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="calculator-personal-gain-result">
    <div class="calculator-personal-gain-result__total total">
      <h5 class="total__title">Итоговая выгода</h5>

      <p class="total__value">{{ result.total }} <span>RC</span></p>

      <button class="total__button-reset" @click="$emit('resetResult')">
        Сбросить рассчет <span></span>
      </button>
    </div>

    <div class="calculator-personal-gain-result__info info">
      <div class="info__team-bonus team-bonus">
        <h6 class="info__title">Командный бонус</h6>

        <p class="info__value info__value_rc">
          {{ result.teamBonus }} <span>RC</span>
        </p>
      </div>

      <div class="info__personal-selling">
        <h6 class="info__title">Личная продажа</h6>

        <div class="info__values">
          <p class="info__value info__value_rc">
            {{ result.personal.RC }} <span>RC</span>
          </p>
          <p class="info__value info__value_pv">
            {{ result.personal.PV }} <span>PV</span>
          </p>
          <p
            class="info__value info__value_cv info__value_cv-left"
            :class="{
              info__value_opacity: result.personal.CVLeft === 0,
            }"
          >
            <span class="info__icon"></span>{{ result.personal.CVLeft }}
            <span>CV</span>
          </p>
          <p
            class="info__value info__value_cv info__value_cv-right"
            :class="{
              info__value_opacity: result.personal.CVRight === 0,
            }"
          >
            <span class="info__icon"></span>{{ result.personal.CVRight }}
            <span>CV</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calculator-personal-gain-result {
  background-color: $dark-third;
  border-radius: $space-s;
  padding: $space-l;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .total {
    &__title {
      margin-bottom: $space-xs;
      @include text-2-bold;
      color: $light-primary;
    }

    &__value {
      @include body-1-bold;
      color: $gold-main;
    }

    &__button-reset {
      border: none;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      @include text-2;
      color: $light-sixth;
      margin-top: $space-m;
      cursor: pointer;

      span {
        mask: url("../../assets/icons/update.svg") 0 0 / cover no-repeat;
        display: inline-block;
        width: 16px;
        height: 16px;
        background: $light-sixth;
        margin-left: $space-s;
      }

      &:hover {
        color: $light-primary;

        span {
          background: $light-primary;
        }
      }

      &:active {
        color: $light-fifth;

        span {
          background: $light-fifth;
        }
      }
    }
  }

  .info {
    margin-left: auto;

    &__team-bonus {
      margin-bottom: 12px;
    }

    &__title {
      margin-bottom: $space-xs;
      @include caption-1;
      color: $light-sixth;
    }

    &__values {
      display: grid;
      align-items: center;
      grid-template-columns: 110px 75px 77px 77px;
      column-gap: $space-s;
    }

    &__value {
      @include body-1-bold;
      color: $light-primary;
      display: flex;
      align-items: center;

      span {
        &:last-child {
          margin-left: $space-xs;
        }
      }

      &_rc {
        span {
          color: $gold-main;
        }
      }

      &_pv {
        span {
          color: $money-pv;
        }
      }

      &_cv {
        span {
          color: $money-cv;
        }
      }

      &_cv-left {
        .info__icon {
          mask: url("../../assets/icons/binary_account_position_left.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_cv-right {
        .info__icon {
          mask: url("../../assets/icons/binary_account_position_right.svg") 0 0 /
            cover no-repeat;
        }
      }

      &_opacity {
        opacity: 0.3;

        span:last-child {
          color: $light-primary !important;
        }
      }
    }

    &__icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: $light-primary;
      margin-right: $space-xs;
    }
  }

  @media (min-width: 1024px) and (max-width: 1220px) {
    .info {
      min-width: 100%;
      margin-top: $space-xl;
    }
  }

  @media (max-width: 900px) {
    .info {
      min-width: 100%;
      margin-top: $space-xl;
    }
  }

  @media (max-width: 576px) {
    .info {
      &__values {
        min-width: auto;
        max-width: 286px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 375px) {
    padding: $space-m;

    .info {
      &__values {
        display: grid;
        grid-template-columns: repeat(2, minmax(70px, 100px));
        grid-template-rows: repeat(2, auto);
        justify-content: flex-start;
        grid-column-gap: $space-s;
        grid-row-gap: $space-s;
      }
    }
  }
}
</style>
