var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"quickstart-bonus-item"},[_c('div',{staticClass:"quickstart-bonus-item__wrapper"},[_c('div',{staticClass:"quickstart-bonus-item__legs"},[_c('div',{staticClass:"quickstart-bonus-item__leg leg"},[_c('h5',{staticClass:"leg__title"},[_vm._v("Команда L")]),_vm._l((_vm.item.left),function(tariff,index){return _c('div',{key:index,staticClass:"leg__tariff-wrapper"},[(tariff.code !== 'empty')?_c('p',{staticClass:"leg__tariff",class:_vm.formatLegTariffClass(tariff.code)},[_c('span'),_vm._v(" "+_vm._s(tariff.name)+" ")]):_c('p',{staticClass:"leg__tariff leg__tariff_empty"},[_vm._v("Пусто")])])})],2),_c('div',{staticClass:"quickstart-bonus-item__leg leg"},[_c('h5',{staticClass:"leg__title"},[_vm._v("Команда R")]),_vm._l((_vm.item.right),function(tariff,index){return _c('div',{key:index,staticClass:"leg__tariff-wrapper"},[(tariff.code !== 'empty')?_c('p',{staticClass:"leg__tariff",class:_vm.formatLegTariffClass(tariff.code)},[_c('span'),_vm._v(" "+_vm._s(tariff.name)+" ")]):_c('p',{staticClass:"leg__tariff leg__tariff_empty"},[_vm._v("Пусто")])])})],2),_c('div',{staticClass:"legs__border-bottom"})]),_c('div',{staticClass:"quickstart-bonus-item__reward reward"},[_c('div',{staticClass:"reward__wrapper"},[_c('p',{staticClass:"reward__title"},[_vm._v(_vm._s(_vm.formattedRewardTitle))]),_c('div',{staticClass:"reward__amounts"},[_c('p',{staticClass:"reward__amount",class:{
              reward__amount_selected: _vm.currentReward0,
            }},[_vm._v(" 0 RC ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip15000),expression:"tooltip15000"}],staticClass:"reward__amount",class:{
              reward__amount_selected: _vm.currentReward15000,
            }},[_vm._v(" 15 000 RC ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip70000),expression:"tooltip70000"}],staticClass:"reward__amount",class:{
              reward__amount_selected: _vm.currentReward70000,
            }},[_vm._v(" 70 000 RC ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip150000),expression:"tooltip150000"}],staticClass:"reward__amount",class:{
              reward__amount_selected: _vm.currentReward150000,
            }},[_vm._v(" 150 000 RC ")])])]),(_vm.totalBonusQuickstart > 0)?_c('div',{staticClass:"reward__benefit benefit benefit_inside"},[_c('h5',{staticClass:"benefit__title"},[_vm._v("Выгода с ББС")]),_c('p',{staticClass:"benefit__amount"},[_vm._v(" "+_vm._s(_vm.totalBenefit.total + _vm.totalBonusQuickstart)+" RC ")])]):_vm._e(),_c('div',{staticClass:"reward__border-bottom"})])]),(_vm.totalBonusQuickstart > 0)?_c('div',{staticClass:"benefit benefit_outside"},[_c('h5',{staticClass:"benefit__title"},[_vm._v("Выгода с ББС")]),_c('p',{staticClass:"benefit__amount"},[_vm._v(" "+_vm._s(_vm.totalBenefit.total + _vm.totalBonusQuickstart)+" RC ")])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }