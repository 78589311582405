<script>
import CalculatorPersonalGainQuickstartBonusItem from "@/components/calculator-personal-gain/CalculatorPersonalGainQuickstartBonusItem";

export default {
  components: { CalculatorPersonalGainQuickstartBonusItem },

  props: {
    bonusQuickstart: {
      type: Array,
      required: true,
    },

    totalBenefit: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      totalBonusQuickstart: 0,
    };
  },

  watch: {
    bonusQuickstart: {
      deep: true,
      handler() {
        this.countTotalBonusQuickstart();
      },
    },
  },

  methods: {
    countTotalBonusQuickstart() {
      this.totalBonusQuickstart = 0;

      this.bonusQuickstart.forEach((item) => {
        let allTariffs = [];

        item.left.forEach((tariff) => {
          allTariffs.push(tariff.code);
        });

        item.right.forEach((tariff) => {
          allTariffs.push(tariff.code);
        });

        if (
          !allTariffs.includes("empty") &&
          !allTariffs.includes("smart_c") &&
          !allTariffs.includes("standart_c") &&
          allTariffs.includes("business_d")
        ) {
          this.totalBonusQuickstart += 15000;
        } else if (
          !allTariffs.includes("empty") &&
          !allTariffs.includes("smart_c") &&
          !allTariffs.includes("standart_c") &&
          !allTariffs.includes("business_d") &&
          allTariffs.includes("premium_d")
        ) {
          this.totalBonusQuickstart += 70000;
        } else if (
          !allTariffs.includes("empty") &&
          !allTariffs.includes("smart_c") &&
          !allTariffs.includes("standart_c") &&
          !allTariffs.includes("business_d") &&
          !allTariffs.includes("premium_d") &&
          allTariffs.includes("vip_d")
        ) {
          this.totalBonusQuickstart += 150000;
        } else {
          this.totalBonusQuickstart += 0;
        }
      });
    },
  },

  mounted() {
    this.countTotalBonusQuickstart();
  },
};
</script>

<template>
  <div class="quickstart-bonus">
    <div class="quickstart-bonus__notice notice">
      <h5 class="notice__title">Бонус быстрого старта</h5>

      <p class="notice__text">
        Получите, если пригласите всех партнеров в течение одного месяца
      </p>
    </div>

    <div class="quickstart-bonus__wrapper">
      <calculator-personal-gain-quickstart-bonus-item
        v-for="(item, index) in bonusQuickstart"
        :key="index"
        :item="item"
        :total-bonus-quickstart="totalBonusQuickstart"
        :total-benefit="totalBenefit"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quickstart-bonus {
  .notice {
    background-color: $light-third;
    border-radius: $space-s;
    padding: $space-l;
    margin-bottom: $space-xs;

    &__title {
      @include text-2-bold;
      color: $dark-primary;
      margin-bottom: $space-xs;
    }

    &__text {
      @include text-2;
      color: $dark-sixth;
      max-width: 270px;
    }
  }

  @media (max-width: 375px) {
    .notice {
      padding: $space-m;
    }
  }
}
</style>
