<script>
import CalculatorPersonalGainNotice from "@/components/calculator-personal-gain/CalculatorPersonalGainNotice";
import MainSelect from "@/components/helpers/MainSelect";
import CalculatorPersonalGainAddPartner from "@/components/calculator-personal-gain/CalculatorPersonalGainAddPartner";
import CalculatorPersonalGainPartner from "@/components/calculator-personal-gain/CalculatorPersonalGainPartner";
import CalculatorPersonalGainResult from "@/components/calculator-personal-gain/CalculatorPersonalGainResult";
import CalculatorPersonalGainResultSmall from "@/components/calculator-personal-gain/CalculatorPersonalGainResultSmall";
import CalculatorPersonalGainQuickstartBonus from "@/components/calculator-personal-gain/CalculatorPersonalGainQuickstartBonus";
import { CALCULATOR_PARTNER_REWARDS } from "@/package/const/calculator-partner-rewards";

const NULL_TOTAL_BENEFIT = JSON.stringify({
  total: 0,
  teamBonus: 0,
  personal: {
    RC: 0,
    PV: 0,
    CVLeft: 0,
    CVRight: 0,
  },
});

export default {
  components: {
    CalculatorPersonalGainQuickstartBonus,
    CalculatorPersonalGainResult,
    CalculatorPersonalGainResultSmall,
    CalculatorPersonalGainPartner,
    CalculatorPersonalGainAddPartner,
    MainSelect,
    CalculatorPersonalGainNotice,
  },

  data() {
    return {
      selectedTariff: null,

      tariffs: [
        {
          id: "business_d",
          name: "Бизнес",
        },
        {
          id: "premium_d",
          name: "Премиум",
        },
        {
          id: "vip_d",
          name: "Франшиза",
        },
      ],

      partners: [],
      totalBenefit: JSON.parse(NULL_TOTAL_BENEFIT),
      bonusQuickstart: [],
      bonusQuickstartLegs: {
        left: [],
        right: [],
      },

      showResultSmall: false,
    };
  },

  watch: {
    selectedTariff: {
      deep: true,
      handler() {
        this.updatePartners();

        localStorage.setItem(
          "selectedTariff",
          JSON.stringify(this.selectedTariff)
        );
      },
    },
  },

  methods: {
    addPartner() {
      const DEFAULT_PARTNER = {
        tariff: {
          id: 1,
          code: "smart_c",
          name: "Клиент Смарт",
        },
        side: {
          id: "left",
          name: "L",
        },
        rewards: CALCULATOR_PARTNER_REWARDS.business_d.smart_c,
      };

      if (this.partners.length === 0) {
        this.partners = [DEFAULT_PARTNER];
      } else {
        this.partners.push(DEFAULT_PARTNER);
      }

      this.updatePartners();
    },

    deletePartner(index) {
      this.partners.splice(index, 1);

      this.updatePartners();
    },

    updatePartners() {
      if (this.partners.length === 0) {
        localStorage.removeItem("partners");
        return;
      }

      let teamBonus = {
        reward: 0,
        CVLeftBefore: 0,
        CVLeftAfter: 0,
        CVRightBefore: 0,
        CVRightAfter: 0,
      };

      this.totalBenefit = JSON.parse(NULL_TOTAL_BENEFIT);

      this.bonusQuickstart = [];
      this.bonusQuickstartLegs = {
        left: [],
        right: [],
      };

      this.partners = this.partners.map((partner) => {
        // * team bonus
        if (
          teamBonus.CVLeftBefore !== 0 &&
          teamBonus.CVRightBefore !== 0 &&
          (teamBonus.CVLeftAfter === 0 || teamBonus.CVRightAfter === 0)
        ) {
          teamBonus.CVLeftBefore = teamBonus.CVLeftAfter;
          teamBonus.CVRightBefore = teamBonus.CVRightAfter;
        }

        if (partner.side.id === "left") {
          teamBonus.CVLeftBefore +=
            CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
              partner.tariff.code
            ].CV;

          teamBonus.CVLeftAfter +=
            CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
              partner.tariff.code
            ].CV;
        }

        if (partner.side.id === "right") {
          teamBonus.CVRightBefore +=
            CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
              partner.tariff.code
            ].CV;

          teamBonus.CVRightAfter +=
            CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
              partner.tariff.code
            ].CV;
        }

        const minCV = Math.min(teamBonus.CVLeftBefore, teamBonus.CVRightBefore);

        teamBonus.reward =
          minCV * CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id].teamBonus;

        if (minCV !== 0) {
          teamBonus.CVLeftAfter = teamBonus.CVLeftBefore - minCV;
          teamBonus.CVRightAfter = teamBonus.CVRightBefore - minCV;
        }

        const currentTeamBonus = Object.assign({}, teamBonus);

        // * total benefit
        this.totalBenefit.total +=
          minCV * CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id].teamBonus;

        this.totalBenefit.total +=
          CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
            partner.tariff.code
          ].RC;

        this.totalBenefit.teamBonus +=
          minCV * CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id].teamBonus;

        this.totalBenefit.personal.RC +=
          CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
            partner.tariff.code
          ].RC;

        this.totalBenefit.personal.PV +=
          CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
            partner.tariff.code
          ].PV;

        this.totalBenefit.personal.CVLeft = teamBonus.CVLeftAfter;
        this.totalBenefit.personal.CVRight = teamBonus.CVRightAfter;

        // * bonus quickstart
        if (partner.side.id === "left") {
          this.bonusQuickstartLegs.left.push(partner.tariff);
        }

        if (partner.side.id === "right") {
          this.bonusQuickstartLegs.right.push(partner.tariff);
        }

        return {
          tariff: partner.tariff,
          side: partner.side,
          rewards:
            CALCULATOR_PARTNER_REWARDS[this.selectedTariff.id][
              partner.tariff.code
            ],
          teamBonus: currentTeamBonus,
        };
      });

      const maxBonusQuickstartLegsLength = Math.max(
        this.bonusQuickstartLegs.left.length,
        this.bonusQuickstartLegs.right.length
      );

      for (let i = 0; i < maxBonusQuickstartLegsLength; i++) {
        if (i % 2 === 0) {
          this.bonusQuickstart.push({
            left: [
              {
                code: "empty",
                name: "Пусто",
              },
              {
                code: "empty",
                name: "Пусто",
              },
            ],
            right: [
              {
                code: "empty",
                name: "Пусто",
              },
              {
                code: "empty",
                name: "Пусто",
              },
            ],
          });
        }

        this.bonusQuickstartLegs.left.sort((currentItem, nextItem) => {
          if (currentItem.id > nextItem.id) {
            return -1;
          }
          if (currentItem.id < nextItem.id) {
            return 1;
          }
          return 0;
        });

        this.bonusQuickstartLegs.right.sort((currentItem, nextItem) => {
          if (currentItem.id > nextItem.id) {
            return -1;
          }
          if (currentItem.id < nextItem.id) {
            return 1;
          }
          return 0;
        });
      }

      let bonusQuickstartIndex = 0;

      for (let i = 0; i < maxBonusQuickstartLegsLength; i++) {
        if (i % 2 === 0 && i !== 0) {
          bonusQuickstartIndex += 1;
        }

        if (this.bonusQuickstartLegs.left.length - 1 >= i) {
          this.bonusQuickstart[bonusQuickstartIndex].left[
            i % 2
          ] = this.bonusQuickstartLegs.left[i];
        }

        if (this.bonusQuickstartLegs.right.length - 1 >= i) {
          this.bonusQuickstart[bonusQuickstartIndex].right[
            i % 2
          ] = this.bonusQuickstartLegs.right[i];
        }
      }

      localStorage.setItem("partners", JSON.stringify(this.partners));
      this.setResultObserver();
    },

    updatePartner(editedPartner) {
      this.partners[editedPartner.index] = {
        side: editedPartner.side,
        tariff: editedPartner.tariff,
      };

      this.updatePartners();
    },

    resetResult() {
      this.partners = [];
      localStorage.removeItem("partners");

      this.totalBenefit = JSON.parse(NULL_TOTAL_BENEFIT);
      this.updatePartners();
    },

    setResultObserver() {
      const result = document.querySelector(
        ".calculator-personal-gain__result"
      );

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio < 0.1) {
              this.showResultSmall = true;
            } else {
              this.showResultSmall = false;
            }
          });
        },
        { threshold: [0.05, 0.15] }
      );

      if (result !== null) {
        observer.observe(result);
      } else {
        observer.disconnect();
      }
    },
  },

  mounted() {
    this.setResultObserver();
  },

  beforeMount() {
    if (JSON.parse(localStorage.getItem("selectedTariff")) !== null) {
      this.selectedTariff = JSON.parse(localStorage.getItem("selectedTariff"));
    }

    if (JSON.parse(localStorage.getItem("partners")) !== null) {
      this.partners = JSON.parse(localStorage.getItem("partners"));
    }
  },
};
</script>

<template>
  <div class="calculator-personal-gain">
    <calculator-personal-gain-notice class="calculator-personal-gain__notice" />

    <main-select
      title="Ваш тариф"
      :selected-option.sync="selectedTariff"
      :options="tariffs"
      style-type="background-white"
    />

    <div class="calculator-personal-gain__partners">
      <calculator-personal-gain-partner
        v-for="(partner, index) in partners"
        :key="index"
        :partner="partner"
        :partner-index="index"
        @updatePartner="updatePartner"
        @deletePartner="deletePartner(index)"
      />
    </div>

    <calculator-personal-gain-add-partner
      class="calculator-personal-gain__add-partner"
      @addPartner="addPartner"
    />

    <calculator-personal-gain-result
      v-if="partners.length"
      class="calculator-personal-gain__result"
      :result="totalBenefit"
      @resetResult="resetResult"
    />

    <calculator-personal-gain-quickstart-bonus
      v-if="partners.length"
      class="calculator-personal-gain__quickstart-bonus"
      :bonus-quickstart="bonusQuickstart"
      :total-benefit="totalBenefit"
    />

    <transition name="fade-fast">
      <calculator-personal-gain-result-small
        v-if="showResultSmall && partners.length"
        class="calculator-personal-gain__result-small"
        :result="totalBenefit"
        @resetResult="resetResult"
      />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.calculator-personal-gain {
  padding: 0 $space-xxl $space-xxxl;
  max-width: calc(866px + 96px); // +96px - padding

  &__notice {
    margin-bottom: $space-xxl;
  }

  &__add-partner {
    margin-top: $space-m;
  }

  &__partners {
    margin-top: $space-l;
  }

  &__result {
    margin-top: $space-xl;
  }

  &__quickstart-bonus {
    margin-top: $space-l;
  }

  &__result-small {
    margin-top: $space-l;
  }

  @media (min-width: 426px) and (max-width: 576px) {
    padding: 0 $space-xl $space-xxxl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l $space-xxl;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m $space-xxl;
  }
}
</style>
